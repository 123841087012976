.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* start below css is for notification page  */
.notiContGrid {
    max-width: 800px;
    margin: auto;
    /* height: auto !important; */
}
.bg-grey{background:#f5f5f5}

.boxShadow{
  box-shadow:0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
}
.notiContGrid ul.MuiList-root {
    width: 100%;
    margin-left: 0;
}
.listMaxwdt{max-width: 80%;}


/* end css is for notification page  */