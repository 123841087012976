@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/SFProText-Regular.eot");
  src: url("./assets/fonts/SFProText-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFProText-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFProText-Regular.woff") format("woff"),
    url("./assets/fonts/SFProText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/SFProText-Medium.eot");
  src: url("./assets/fonts/SFProText-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFProText-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFProText-Medium.woff") format("woff"),
    url("./assets/fonts/SFProText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/SFProText-Semibold.eot");
  src: url("./assets/fonts/SFProText-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/SFProText-Semibold.woff2") format("woff2"),
    url("./assets/fonts/SFProText-Semibold.woff") format("woff"),
    url("./assets/fonts/SFProText-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "SF Pro Text" !important;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

header {
  background: #4852f7 !important;
  height: 70px;
  justify-content: center;
  z-index: 10 !important;
}

.nav-route {
  text-decoration: none;
  color: white;
  margin: 15px 12px;
  text-transform: uppercase;
}

.user-pr-btn {
  border: 2px solid #fff !important;
  border-radius: 100% !important;
  width: 55px;
  height: 55px;
  object-fit: cover;
  padding: 0px !important;
}

.user-pr-btn img {
  width: 100%;
}

.logo-div img {
  margin-top: 5px;
}

.user-popover .MuiPopover-paper {
  top: 70px !important;
  text-transform: uppercase;
  min-width: 180px;
  background: #fff !important;
  font-family: "SF Pro Text" !important;
  padding: 0px 20px;
}

.user-popover .MuiPopover-paper .logout-css {
  color: #457ffa !important;
  font-family: "SF Pro Text" !important;
  font-size: 18px !important;
  text-transform: uppercase;
  font-weight: 500;
  border-top: 1px solid rgba(112, 112, 112, 0.4);
}

.user-popover ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.user-popover ul li {
  padding: 10px 0px !important;
}

.user-popover ul li:hover {
  background-color: transparent !important;
}

.user-popover .MuiPopover-paper {
  right: 12px !important;
  left: unset !important;
}

.user-popover .MuiPopover-paper,
.mobile-submenu .MuiPopover-paper {
  overflow-y: unset !important;
  overflow-x: unset !important;
}

@media screen and (min-width: 1921px) {
  .booking-list-window {
    height: 61vh !important;
  }

  .booking-list-window-monthly {
    height: 47vh !important;
  }

  .left-33.left-33 {
    left: 25% !important;
  }
}

.ml-auto {
  margin-left: auto;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.QRbooking .MuiCheckbox-root {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.QRbooking .link-1 {
  font-weight: 600 !important;
  padding: 5px 10px;
  color: #fff !important;
  font-size: 14px !important;
  background-color: #3c71ff !important;
  border-radius: 30px;
}

.QRbooking .title-div+h6 {
  margin: 8px 0px !important;
}

.QRbooking .heading-card {
  font-weight: 600 !important;
}

.big-total h6 {
  color: rgba(0, 0, 0, 0.72) !important;
}

.big-total .font-14-style {
  font-size: 18px !important;
  color: rgba(0, 0, 0) !important;
  font-weight: bolder;
}

.QRbooking .small-font {
  font-size: 12px !important;
}

.QRbooking .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.QRbooking .img-con {
  height: 100% !important;
}

.scanner-con {
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.scanner-con img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  object-position: top;
}

.QRbooking .img-con img {
  width: 100%;
  object-position: top;
  height: 100%;
  object-fit: cover;
}

.QRbooking .box {
  background-color: #fff;
}

.QRbooking .MuiFormControl-root {
  width: 100%;
}


.saved-vehicles .saved-items {
  width: 48%;
  word-break: break-all;
}

.no-notification {
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.mt-20 {
  margin-top: 20px;
}

.login-card {
  text-align: center;
  padding: 25px 70px !important;
  box-sizing: border-box;
}

.center-box {
  justify-content: center;
  padding: 0px !important;
}

.cardDesc {
  float: left;
}

.map-card .cardImg {
  width: 100%;
  height: 110px;
  max-width: 100%;
}

.cardImg {
  float: left;
  background-size: cover;
  height: 180px;
  border-radius: 20px;
  max-width: 151px;
  width: 100%;
}

.carIcon {
  float: right;
  padding: 5px !important;
}

.title-tag {
  font-size: 35px !important;
  line-height: 46px;
  font-family: "SF Pro Text" !important;
  font-weight: normal;
  text-transform: capitalize;
}

.description-space {
  margin: 0px 0px 6px;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.cardMain {
  background: #3c71ff !important;
  padding: 15px;
  border-radius: 20px !important;
  margin: 0 10px;
}

.cardDesp {
  display: inline-block;
}

.cardDesp .MuiCardContent-root {
  padding: 5px 5px 5px 15px !important;
}

.text-white {
  color: #fff !important;
}

.price-btn {
  margin: 10px 0px;
}

.cardBtn {
  background: #fff !important;
  color: #007aff !important;
  border-radius: 13px !important;
  font-family: "SF Pro Text" !important;
  font-size: 16px !important;
  padding: 5px 19px !important;
}

.cardMain {
  margin: 5px 30px;
}

.small-font {
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.72) !important;
  font-weight: 500 !important;
  font-family: "SF Pro Text" !important;
  line-height: 16px !important;
}

.sideSearch {
  background: #fff !important;
  margin-bottom: 20px;
  position: relative;
}

.mapSideNav {
  background: #f5f5f5;
  height: calc(100vh - 70px);
  position: relative;
  border-radius: 0px 0px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

img.fullScreenImg {
  width: 100%;
}

.center-div {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 400px;
  height: 47px;
  background-color: red;
  border-radius: 3px;
  padding: 10px;
}

.sideSearch {
  margin: 0px !important;
}

.amount-txt {
  font-size: 30px;
  font-family: "SF Pro Text" !important;
  font-weight: 500 !important;
}

.heading-card {
  font-family: "SF Pro Text" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.card-content {
  padding: 0px !important;
}

.login-box .MuiFormControl-root {
  width: 100%;
  margin-bottom: 25px !important;
}

.p-0 {
  padding: 0px !important;
}

.tabs-div {
  padding: 5px 20px;
}

.sideSearch .MuiInputBase-root {
  width: 100% !important;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
}

.absolute-search {
  position: absolute !important;
  right: 0px;
  height: 100%;
  background-color: #457ffa !important;
  border-radius: 0px 10px 0px 0px !important;
  padding: 9px 0px !important;
}

.searchcard {
  margin: 30px 20px 10px !important;
  padding: 0px !important;
  border-radius: 10px 10px 20px 20px !important;
}

.light-grey-top {
  background-color: #f5f5f5;
  margin-top: 10px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 0px 10px 0px 0px;
  padding: 5px 15px;
  border-left: 3px solid #007aff;
}

.light-grey-bottom {
  background-color: #f5f5f5;
  position: relative;
  margin-bottom: 10px;
  border-radius: 0px 0px 10px 0px;
  padding: 5px 15px;
  border-left: 3px solid #ffdf2b;
}

.light-grey-top input,
.light-grey-bottom input {
  padding: 0px !important;
}

.br-none {
  border-radius: 0px !important;
}

.textField {
  width: 100%;
  margin: 0px 0px 10px;
  font-size: 14px !important;
  font-family: "SF Pro Text" !important;

}

.textField1 {
  width: 100%;
  margin: 0px 0px 10px;
  font-size: 14px !important;
  font-family: "SF Pro Text" !important;
}

.top-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  top: -3px;
  left: -7px;
  background-color: #fff;
  border: 3px solid #007aff;
}

.top-line {
  position: absolute;
  width: 3px;
  height: 10px;
  left: -3px;
  top: -10px;
  background-color: #007aff;
}

.bottom-line {
  position: absolute;
  width: 3px;
  height: 10px;
  left: -3px;
  top: -10px;
  background-color: #ffdf2b;
}

.bottom-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  bottom: -3px;
  left: -7px;
  background-color: #fff;
  border: 3px solid #ffdf2b;
}

.search-label {
  font-size: 16px !important;
  line-height: 19px !important;
  margin-bottom: 6px !important;
  font-family: "SF Pro Text" !important;
  font-weight: 500;
  display: block;
}

.light-grey-top .MuiInput-underline::after,
.light-grey-top .MuiInput-underline::before,
.light-grey-bottom .MuiInput-underline::after,
.light-grey-bottom .MuiInput-underline::before {
  border-bottom: none !important;
}

.middle-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  top: -10.5px;
  left: -7px;
  background-color: #fff;
  border: 3px solid #007aff;
}

.tabs-div .MuiBox-root {
  padding: 0px !important;
}

.light-grey-top .textField,
.light-grey-bottom .textField {
  margin: 3px 0px 3px !important;
}

.tabs-div .tab-top {
  margin-bottom: 20px;
}

.tabs-div .MuiInputBase-input {
  height: auto !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "SF Pro Text" !important;
  font-weight: 600;
}

.tabs-div .tab-top .tab-btn {
  color: #000000;
  font-size: 18px;
  min-width: 125px;
  margin: 0px 6px;
  font-family: "SF Pro Text" !important;
}

.sideSearch .MuiInputBase-input {
  font-family: "SF Pro Text" !important;
  color: rgba(0, 0, 0, 0.72);
  padding: 15px 72px 15px 15px !important;
  font-size: 16px !important;
  font-weight: normal !important;
}

.tabs-div .tab-top .MuiTabs-indicator {
  background-color: #007aff;
  height: 4px !important;
}

.bg-box {
  background: url("./assets/images/login_bg.jpg") no-repeat;
  width: 100%;
  background-size: cover;
  background-position: top center;
}

.login-box {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.txtdesc {
  font-size: 14px !important;
  font-family: "SF Pro Text" !important;
  margin-bottom: 20px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.72) !important;
  margin-top: 15px !important;
}

.MuiFormLabel-root {
  font-size: 16px !important;
  font-family: "SF Pro Text" !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
  font-family: "SF Pro Text" !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.72) !important;
  padding: 6px 0 6px !important;
}

.login-btn {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #457ffa !important;
  text-transform: uppercase !important;
}

.forgot-btn {
  margin: 10px 0px 20px !important;
}

.bottom-txt {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.72) !important;
  font-weight: 500 !important;
  margin-top: 20px !important;
}

.bottom-txt button {
  color: #457ffa !important;
  padding: 0px !important;
  text-transform: capitalize !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.find-booking {
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.find-box {
  max-width: 870px !important;
  width: 85%;
  margin: 0 auto;
}

.sideSearch.findpage .MuiInputBase-root {
  border-bottom: none !important;
}

.sideSearch.findpage .absolute-search {
  border-radius: 0px 10px 10px 0px !important;
  min-width: 110px;
}

.find-booking-search,
.sideSearch.findpage {
  border-radius: 15px !important;
}

.sideSearch.findpage .MuiInputBase-input {
  padding: 18px 115px 18px 12px !important;
}

.forgot-pass {
  color: #457ffa !important;
  font-family: "SF Pro Text" !important;
  text-transform: capitalize !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-top: -22px !important;
}

.forgot-pass:hover {
  text-decoration: none !important;
  background-color: transparent !important;
}

/* .MuiButton-root:hover, .MuiIconButton-root:hover{background-color: transparent !important;} */
.cardBtn:hover {
  background: #fff !important;
  color: #007aff !important;
}

.x-mark {
  float: right;
}

.perhour {
  font-size: 12px !important;
  margin-left: 2px;
}

.close-page {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.close-page .cross-icon {
  display: flex;
}

.delete-addrs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.delete-icon {
  color: rgb(220, 0, 78) !important;
  background-color: transparent !important;
  padding: 0px !important;
  min-width: auto !important;
  box-shadow: none !important;
}

.icons {
  font-size: 20px !important;
  margin-right: 5px !important;
  display: inline-block;
  vertical-align: middle;
}

.map-con {
  height: calc(100vh - 70px);
  width: 100%;
}

.tab-details,
.parking-detail {
  overflow-y: auto;
  max-height: calc(100vh - 68px);
}

.login-box.signup .bottom-txt {
  margin-top: 0px !important;
}

.login-box.signup .login-card {
  padding: 15px 70px !important;
}

.login-box.signup .error-msg {
  margin-top: -10px;
}

.login-box.reset-pass .error-msg {
  margin-top: -10px;
}

.login-box.signup .error-msg-1 {
  margin-top: 0px;
}

.login-box.signup .forgot-btn {
  margin: 10px 0px 10px !important;
}

.login-btn.forgot-btn {
  margin: 10px 0px 0px !important;
}

.light-grey-top button,
.light-grey-bottom button {
  padding: 0px !important;
}

.light-grey-top button svg,
.light-grey-bottom button svg {
  fill: #3c71ff;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 0.7 !important;
}

.content__body {
  /* height: calc(100vh - 16vh);
  max-height: calc(100vh - 16vh) !important; */
  height: calc(100vh - 30vh);
  max-height: calc(100vh - 30vh) !important;
}

.sendNewMessage input,
.sendNewMessage textarea {
  background-color: #fff !important;
}

.sendNewMessage,
.content__footer {
  background-color: #f5f5f5 !important;
}

/* Booking details View Card */
.box {
  -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  -moz-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  padding: 10px;
}

.parking-detail {
  padding: 20px 30px;
}

.page-heading {
  color: #000000 !important;
  font-size: 18px !important;
  font-family: "SF Pro Text" !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

/* .page-heading::after{content: "";  height: 1.5px;  background-color: #ddd;  width: 100%;  display: inline-block;  position: absolute;  left: 0;  bottom: 0;} */
.hours-op div {
  margin: 10px 20px 0px 0px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.con-1 {
  padding: 10px;
}

.info-txt {
  margin-top: 20px !important;
}

.h6-style {
  font-size: 16px !important;
  font-family: "SF Pro Text" !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.font-14-style {
  font-size: 14px !important;
  font-family: "SF Pro Text" !important;
  color: rgba(0, 0, 0, 0.72) !important;
  font-weight: 500 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.saved-card-con {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

.link-1 {
  font-weight: 500 !important;
  color: #3c71ff !important;
  display: inline-block;
  font-size: 16px !important;
  font-family: "SF Pro Text" !important;
}

.link-1:hover {
  text-decoration: none !important;
}

.radio {
  padding: 0px !important;
}

.title-bar {
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid #ddd;
  padding-bottom: 6px;
  margin-bottom: 12px;
  margin-top: 10px;
}

/* .edit-btn{border:1.7px solid rgba(150, 94, 94, 0.24); background-color: #fff; box-shadow: 0px 3px 6px rgba(0,0,0,0.16); color:#3C71FF ; font-size: 9px; text-transform: uppercase;
  padding: 6px 25px;  margin-left: auto;  border-radius: 10px; font-family: 'SF Pro Text' !important;   display: inline-block;    font-weight: 600 !important;} */
.edit-btn {
  font-size: 9px !important;
  text-transform: uppercase !important;
  padding: 6px 25px !important;
  margin-left: auto !important;
  border-radius: 10px !important;
  font-family: "SF Pro Text" !important;
  display: inline-block;
  font-weight: 600 !important;
}

.btn-primary {
  background-color: #3c71ff !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  font-size: 16px !important;
}

.btn-box {
  text-align: right;
}

.img-con {
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
}

.img-con img {
  width: 100%;
  object-position: 100% 50%;
}

.p-box {
  padding: 0px 10px;
}

/* .tabs-div .MuiTabs-flexContainer{ justify-content: center;} */
.rposition {
  position: relative;
}

.logo-div img {
  width: 150px;
  margin-top: 3px;
}

.Add-vehicle-popup .MuiGrid-spacing-xs-2 {
  margin: 0 !important;
}

.Add-vehicle-popup .MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

/* My Account */
.account-con .tabs-div .MuiTabs-flexContainer {
  justify-content: center;
  background-color: #f5f5f5;
}

.profile-pic {
  width: 150px;
  height: 150px;
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  border: 3px solid #007aff !important;
  overflow: hidden;
  margin: 30px auto 5px;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.verticle-tab .tab-btn {
  min-width: 100% !important;
  margin: 0px !important;
}

.verticle-tab {
  margin-top: 25px;
}

.tabs-div .verticle-tab .MuiTabs-indicator {
  height: 38px !important;
  width: 4px !important;
}

.tabs-div .verticle-tab .tab-btn.Mui-selected {
  background-color: #fff;
}

.tab-details {
  padding: 30px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.w-full {
  width: 100% !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #007aff !important;
}

.profile-name {
  text-align: center;
  margin: 12px 0px 0px !important;
}

.tab-details h5 {
  text-transform: uppercase;
}

.tabs-div .tab-top.verticle-tab .tab-btn {
  font-size: 16px;
}

.profile-con {
  text-align: center;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #007aff !important;
}

.settings-checkbox .MuiCheckbox-root {
  padding: 5px 9px;
}

.mt-10 {
  margin-top: 10px !important;
}

.ml-8 {
  margin-left: 8px;
}

.mt-5 {
  margin-top: 5px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

.title-div+h6 {
  min-height: 38px;
}

.small-link .MuiButton-label {
  justify-content: flex-start;
}

.user-popover .MuiPopover-paper::before,
.mobile-submenu .MuiPopover-paper::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  display: block;
  position: absolute;
  top: -8px;
  right: 15px;
}

.mr-15 {
  margin-right: 15px !important;
}

.makeStyles-userProfile-6,
.jss6 {
  border-radius: 100% !important;
}

header+.account-con {
  display: none;
}

/* MyBookings */
.upcoming-card {
  max-width: 100% !important;
  margin: 5px 10px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) !important;
}

.cardMain.map-card.upcoming-card .cardDesp {
  width: 100%;
}

.btn-2 {
  background-color: #007aff !important;
  color: #fff !important;
  padding: 6px 30px !important;
}

/* logout-popup */
.m-0 {
  margin: 0px !important;
}

.logout-popup .MuiDialogTitle-root {
  padding: 10px 24px !important;
}

.logout-popup .MuiDialog-paperWidthSm {
  max-width: 400px;
}

.logout-popup .MuiButton-textPrimary {
  color: #3c71ff !important;
}

.booking-list-window .cardImg,
.booking-list-window-monthly .cardImg {
  object-position: 69% 50%;
}

/* Map View Card */
.bg-gray {
  background-color: #f5f5f5;
}

.map-card {
  max-width: 245px;
}

.cardMain.map-card {
  background-color: #ffffff !important;
}

.text-grey {
  color: rgba(0, 0, 0, 0.72) !important;
}

.map-icon {
  color: rgba(0, 0, 0, 0.72) !important;
  vertical-align: middle;
  font-size: 12px;
}

.text-black {
  color: #000 !important;
}

.payment {
  color: green !important;
}

.cardMain.map-card .cardBtn {
  background-color: #007aff !important;
  color: #fff !important;
}

.text-blue {
  color: #007aff;
}

.title-div {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.carIcon.text-blue {
  padding: 0px !important;
  color: #007aff !important;
}

.title-car-icon {
  margin-left: auto;
}

.cardMain.map-card .cardDesp .MuiCardContent-root {
  padding: 5px 5px 5px 5px !important;
}

.cardMain.map-card .cardDesp {
  display: inline-block;
  margin-top: 10px;
  width: 100%;
}

.MuiPaper-rounded {
  border-radius: 20px !important;
}

.p-0 {
  padding: 0px !important;
}

.slide-controller {
  cursor: pointer;
  background-color: #3c71ff !important;
  width: 35px;
  border-radius: 0px 10px 10px 0px !important;
  height: 90px;
  padding: 0px !important;
  position: absolute;
  right: -35px;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.arrow-icon {
  color: #fff;
}

.check-text .MuiFormControlLabel-label,
.check-text {
  font-weight: 600;
  text-align: left;
  font-size: 12px !important;
  font-family: "SF Pro Text" !important;
}

.check-text {
  display: flex;
  margin-top: 5px;
}

.booking-list-window {
  height: 58vh;
  overflow-y: scroll;
}

.booking-list-window-monthly {
  height: 43vh;
  overflow-y: scroll;
}

@media screen and (max-width: 1750px) {
  .booking-list-window {
    height: 55vh;
  }

  .booking-list-window-monthly {
    height: 39.5vh;
  }

  .content__footer {
    bottom: 6vh !important;
  }
}

@media screen and (max-width: 1680px) {
  .booking-list-window {
    height: 49vh;
  }

  .booking-list-window-monthly {
    height: 32vh;
  }

  .content__footer {
    bottom: 4vh !important;
  }
}

@media screen and (max-width: 1367px) {

  .cardMain,
  .searchcard {
    margin: 5px 20px 10px;
  }

  .logo-div img {
    width: 125px;
    margin-top: 3px;
  }

  .price-btn {
    margin: 8px 0px;
  }

  .content__footer {
    bottom: 3vh !important;
  }

  .title-div+h6 {
    min-height: 45px;
  }

  .title-tag {
    font-size: 24px !important;
  }

  .login-box .MuiFormControl-root {
    margin-bottom: 18px !important;
  }

  .login-card {
    padding: 15px 70px !important;
  }

  header {
    height: 65px;
  }

  .find-booking {
    height: calc(100vh - 65px);
  }

  .map-con {
    height: calc(100vh - 65px);
    width: 100%;
  }

  .user-popover .MuiPopover-paper {
    top: 65px !important;
    right: 12px !important;
    left: unset !important;
  }

  .mapSideNav {
    height: calc(100vh - 65px);
  }

  .nav-route {
    font-size: 14px;
  }

  .user-pr-btn {
    width: 50px;
    height: 50px;
  }

  .cardImg {
    max-width: 125px;
  }

  .title-div {
    margin-bottom: 5px;
  }

  .heading-card {
    font-size: 14px !important;
  }

  .small-font {
    font-size: 11px !important;
    line-height: 15px !important;
  }

  .cardMain {
    padding: 10px 10px;
  }

  .searchcard {
    margin: 15px 20px 10px !important;
  }

  .booking-list-window {
    height: 47vh;
  }

  .booking-list-window-monthly {
    height: 27.5vh;
  }

  .tabs-div .tab-top .tab-btn {
    font-size: 16px;
    min-height: 30px;
    padding: 4px 12px;
    line-height: 30px;
  }

  .tab-details,
  .parking-detail {
    overflow-y: auto;
    max-height: calc(100vh - 64px);
  }

  .scrollNotification {
    overflow-y: auto;
    height: calc(100vh - 70px);
  }
}

@media screen and (max-width: 1024px) {
  .nav-route {
    font-size: 13px;
  }

  .logo-div img {
    width: 60%;
  }

  header {
    height: 55px;
  }

  .user-pr-btn {
    width: 45px;
    height: 45px;
  }

  .find-booking {
    height: calc(100vh - 55px);
  }

  .map-con {
    height: calc(100vh - 55px);
    width: 100%;
  }

  .user-popover .MuiPopover-paper {
    top: 55px !important;
  }

  .tab-details {
    padding: 30px 15px;
  }

  .mobile-submenu .MuiPaper-root.MuiPopover-paper {
    background: #fff !important;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .cardImg {
    max-width: 100%;
    height: 120px;
  }

  .cardDesp {
    display: inline-block !important;
  }

  .booking-list-window {
    height: 54vh;
  }

  .booking-list-window-monthly {
    height: 54vh;
  }

  .mapSideNav {
    height: calc(100vh - 54px);
  }
}

@media screen and (max-width: 979px) {
  body {
    overflow-y: auto !important;
  }

  header {
    height: 50px;
    z-index: 10 !important;
  }

  .find-booking {
    height: calc(100vh - 50px);
  }

  .map-con {
    height: calc(100vh - 50px);
    width: 100%;
  }

  .sideSearch.findpage .MuiInputBase-input {
    padding: 12px 100px 12px 12px !important;
  }

  .sideSearch.findpage .absolute-search {
    min-width: 80px;
  }

  .find-box .title-tag {
    font-size: 22px !important;
  }

  .logo-div img {
    width: 55%;
  }

  .login-box .login-card {
    text-align: center;
    padding: 15px 30px !important;
    min-width: 100% !important;
    margin: 10px 0px;
  }

  .login-box {
    width: 60%;
  }

  .bottom-txt {
    margin-top: 10px !important;
  }

  .txtdesc {
    margin-top: 4px !important;
  }

  .login-box .MuiFormControl-root {
    margin-bottom: 15px !important;
  }

  .login-btn {
    font-size: 18px !important;
  }

  .mapSideNav {
    height: auto;
    padding-bottom: 15px;
  }

  .mobile-submenu .MuiPopover-paper {
    top: 52px !important;
  }

  .mobile-submenu ul li p {
    margin: 2px 0px !important;
    text-transform: uppercase;
  }

  .account-con .tabs-div .MuiTabs-flexContainer {
    flex-direction: row;
    display: inline-block;
    padding: 0px 10px;
  }

  .verticle-tab .tab-btn {
    min-width: inherit;
  }

  .tabs-div .verticle-tab .MuiTabs-indicator {
    height: 0px !important;
    width: 0px !important;
  }

  .verticle-tab .MuiTabs-scroller {
    overflow-x: scroll !important;
  }

  .tab-details {
    padding: 20px 10px;
  }

  .upcoming-card {
    margin: 5px 10px 20px !important;
  }

  .mobile-submenu .MuiPaper-root.MuiPopover-paper {
    background: #fff !important;
  }

  .booking-list-window {
    height: auto;
  }

  .booking-list-window-monthly {
    height: auto;
  }

  .left-33 {
    display: none;
  }

  .cardImg {
    max-width: 200px;
  }
}

@media screen and (max-width: 767px) {

  .scrollNotification,
  .tab-details,
  .parking-detail {
    height: auto !important;
    max-height: inherit !important;
  }

  .account-con .tabs-div .MuiTabs-flexContainer {
    flex-direction: column;
    display: flex;
    padding: 0px 10px;
  }

  .img-con {
    width: 100% !important;
    height: 180px !important;
  }

  header {
    position: fixed !important;
  }

  .account-con,
  .find-booking,
  .login-box,
  .mapSideNav,
  .scrollNotification {
    padding-top: 50px;
  }

  .login-box {
    min-height: calc(100vh - 50px);
  }

  .css-nvf14r-ToastContainer {
    z-index: 9999 !important;
    top: 50px !important;
  }
}

@media screen and (max-width: 415px) {
  .cardImg {
    width: 100% !important;
    max-width: 100%;
    height: 120px;
  }

  .cardDesp {
    display: inline-flex !important;
    width: 100%;
  }

  .cardDesp .MuiCardContent-root {
    padding: 15px 0px 0px 0px !important;
    width: 100%;
  }

  .login-box {
    width: 90%;
  }

  .title-tag {
    font-size: 20px !important;
  }

  .forgot-pass {
    margin-top: -15px !important;
    font-size: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .login-box.signup .login-card {
    padding: 15px 25px !important;
  }

  .p-box {
    margin-top: 10px;
  }

  .upcoming-card {
    margin: 5px 0px 20px !important;
  }

  .formobile-chat {
    position: absolute;
    top: 12px;
    right: 20px;
  }
}

.color-danger {
  color: #f44336 !important;
}

.map_image_icon {
  position: relative;
}

.map_image_icon span:nth-child(2) {
  position: absolute;
  top: 14px;
  left: 8px;
  right: 0;
  border-radius: 50%;
}

.map_image_icon img:nth-child(3) {
  position: absolute;
  top: 0;
  right: -24px;
  left: unset;
}

.badge {
  box-sizing: border-box;
  display: inline-block;
  /* background-color: #2c3e50; */
  border-radius: 3rem;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  /* padding: .7rem .5rem .0rem; */
  line-height: inherit;
}

.primary-theme-color {
  color: #007aff !important;
}

.rotate-180deg {
  transform: rotate(180deg);
}

.left-0 {
  left: 0px !important;
  top: 45% !important;
}

.left-33 {
  left: 33.33% !important;
  top: 45% !important;
}

.autocomplete-dropdown-container {
  width: 100%;
  position: absolute;
  z-index: 10;
}

.autocomplete-dropdown-container .suggestion-item {
  padding: 10px;
}

.autocomplete-dropdown-container .suggestion-item--active {
  padding: 10px;
  background: #007aff !important;
  color: white;
}

#sq-card-number {
  font-size: 49px !important;
}

.margin-0 {
  margin: 0px !important;
}

.popover-map .MuiPaper-root.MuiPopover-paper {
  background: transparent;
}

.displayNone {
  display: none !important;
}

.width500 {
  width: 500px;
}

.mt-2 {
  margin-top: -2px !important;
}

.autocomplete-dropdown-container.add-address {
  width: 90%;
}

.MuiFormLabel-asterisk {
  color: #f44336 !important;
}

.nav-route1 {
  text-decoration: none;
  color: rgb(0 0 0 / 87%);
}

.genderError {
  text-align: left !important;
  margin-bottom: -12px !important;
  margin-top: 12px !important;
}

.popupButtion {
  margin-right: 30px !important;
}

.popupButtion.mr-15 {
  margin-right: 12px !important;
}

.check-text input {
  margin-left: 0px !important;
  width: 20px;
  height: 20px;
  margin-top: 0px;
}

.headerImage {
  border-radius: 20px !important;
}

.rightAlign {
  text-align: end;
}

.scrollNotification {
  overflow-y: auto;
  height: calc(100vh - 70px);
}

.changePassword {
  margin-top: 15px !important;
  width: 120px;
}

.MuiAccordion-root:before {
  height: 0px !important;
}

.AccMargin {
  margin: 2px !important;
}

.SubmitSquerButtion {
  display: block !important;
  font-size: 16px !important;
  width: calc(100% - 30px) !important;
  height: 40px !important;
  margin-top: 100px !important;
  margin: 70px 15px 10px !important;
  background-color: #3c71ff !important;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #3c71ff !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  transition: all 100ms ease-in-out !important;
  will-change: transform, background-color, box-shadow !important;
}

.AppWrapperSequare {
  width: 100%;
  /* height: 400px; */
  position: relative;
}


.GenderError {
  margin-bottom: 10px;
  margin-top: -4px;
}

.m20 {
  margin-top: -20px !important;
}

.Margin30 {
  margin: 30px !important;
}

.sq-payment-form {
  width: inherit !important;
  align-self: center !important;
  text-align: center !important;
  justify-content: center !important;
}

.sq-creditcard {
  height: 40px !important;
  background: #3c71ff !important;
  text-transform: uppercase;
  align-self: center !important;
  text-align: center !important;
  width: 100%;
}

@media only screen and (max-width: 786px) and (min-width: 375px) {
  .sq-payment-form {
    width: 250px !important;
    align-self: center !important;
    text-align: center !important;

  }

  .sq-creditcard {
    height: 30px !important;
    background: #3c71ff !important;
    text-transform: uppercase;
    width: 250px !important;
    align-self: center !important;
    text-align: center !important;

  }
}

@media only screen and (max-width:1000px) and (min-width:786px) {
  .sq-card-form-wrapper {
    width: 1000px !important;
  }
}

.scrollNotification2 {
  overflow-y: auto;
  height: 100vh;
}

.bookingHeader {
  font-weight: 700;
}

.marginGrid {
  margin: 15px;
}

.ptagSpace {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.ptagDetails {
  color: #757575;
  font-weight: 400;
}

.requestForVahele {
  background: #ffffff !important;
  margin-top: 5% !important;
}

.requestCard {
  max-width: 500px !important;
}

a {
  text-decoration: none !important;
}

.sendbtn {
  transform: rotate(320deg);
  font-size: 1.5rem;
}

.main__chatcontent {
  flex-grow: 1;
  padding: 0px 10px 9vh;
  max-width: 100%;
  margin-top: 3px;
  border-right: 1.5px solid #bdbdbd;
  border-left: 1.5px solid #bdbdbd;
  border-bottom: 1.5px solid #bdbdbd;
}

.content__header {
  padding-bottom: 15px;
  border-bottom: 1.5px solid #bdbdbd;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  background-color: #4852f7 !important;
  padding-top: 10px;
  color: #fff;
}

.current-chatting-user {
  display: flex;
  align-items: center;
}

.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}

.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings .btn-nobg {
  color: #000;
}

.content__body {
  max-height: calc(120vh - calc(120vh / 2));
  overflow-x: hidden;
}

.chat__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s;
  /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal;
  /* or: normal */
  animation-timing-function: cubic-bezier(0.88,
      0.19,
      0.37,
      1.11);
  /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both;
  /* or: backwards, both, none */
  animation-delay: 0.2s;
  /* or: Xms */
}

@keyframes showIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.chat__item .avatar {
  margin-right: 0px;
  margin-left: 20px;
  background: #fff;
  padding: 1px;
}

.chat__item__content {
  background-color: #4462ff;
  color: #fff;
  padding: 15px;
  border-radius: 10px 10px 0 10px;
  max-width: 50%;
  min-width: 215px;
}

.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}

.chat__item__content .chat__meta span {
  font-size: 14px;
  color: #8693d3;
  user-select: none;
}

.chat__msg {
  user-select: none;
}

.chat__item.other {
  flex-direction: row-reverse;
  transform-origin: left;
}

.chat__item.other .chat__item__content {
  background-color: #fff;
  color: #000;
  border-radius: 10px 10px 10px 0;
  max-width: 50%;
}

.chat__item.other .avatar {
  margin-right: 20px;
  margin-left: 0px;
}

.chat__item.other .chat__item__content .chat__meta span {
  color: #d1d1d1;
}

.content__footer {
  padding-bottom: 0px;
  padding-top: 0px;
  position: fixed;
  /* left: 0; */
  bottom: 7vh;
  width: 30%;
  background-color: #fff;
}

.chatinput {
  width: 100%;
  resize: none;
  /* border:1px solid rgb(18 27 31); */
  border: 1px solid #bdbdbd;
  font-size: 15px;
  font-family: "SF Pro Text";
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px;
}

.chatinput :focus {
  outline: none;
  margin-right: 10px;
  resize: none;
}

.chatinput:focus-within {
  border: 1px solid rgb(72, 82, 247);
  outline: none;
  border-radius: 10px;
  margin-right: 10px;
  height: auto;
}

@media screen and (max-width: 786px) {
  .content__footer {
    padding-bottom: 0px;
    position: fixed;
    bottom: 0;
    width: 93%;
    background-color: turquoise;
    left: 4%;
  }

  .main__chatcontent {
    /* padding: 0px 20px 10vh; */
    margin-top: 15%;
  }
}


@media screen and (max-width: 425px) {
  .content__footer {
    padding-bottom: 0px;
    margin-top: 15%;
    margin-bottom: -14px;
  }

}

@media only screen and (max-width: 786px) and (min-width: 425px) {
  .main__chatcontent {
    /* padding: 0px 20px 10vh; */
    margin-top: 1%;
  }
}


.sendNewMessage {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
  border-radius: 10px;
  justify-content: center;
}

.sendNewMessage button {
  width: 44px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #4665ff;
  padding: 0;
  justify-content: center;
  border-radius: 20px;
  /* line-height: 36px; */
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.sendNewMessage button:hover {
  transform: scale(1.1);
}

.sendNewMessage button i {
  display: block;
}

.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}

#sendMsgBtn {
  background-color: #3b5bfe;
  color: #fff;
}

.file-type {
  position: absolute;
  width: 88px;
  z-index: 2;
  opacity: 0;
}

.profile-pic-small {
  object-fit: cover;
  object-position: top;
}

.messagecontainer {
  background-color: #4852f7;
  width: auto;
  word-break: break-all;
  max-width: 80%;
  border-radius: 10px;
  padding: 5px 8px;
  display: inline-block;
}

.messagecontainer1 {
  background-color: #ffff;
  width: auto;
  max-width: 80%;
  border-radius: 10px;
  padding: 5px 8px;
  border: 1px solid rgb(158, 158, 158, 0.3);
  background: #ffff;
  word-break: break-all;
  display: inline-block;
}

.receiveDateTime {
  color: rgb(148 148 148);
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 10px;
  text-align: end;
}

.sendDateTime {
  color: rgb(255, 255, 255, 0.8);
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 10px;
  text-align: end;
}

.customerMessage {
  color: #ffff;
  margin: 3px;
  font-size: 14px;
  line-height: 18px;
}

.receivemessage {
  color: #000;
  margin: 3px;
  font-size: 14px;
  line-height: 18px;
}

#date-picker-dialog-label {
  color: grey !important
}


.cardholdername {
  border: 1px solid rgb(238, 236, 236) !important;
  height: 40px !important;
  width: 95% !important;
  box-shadow: 0 6px 9px rgb(50 50 93 / 6%),
    0 2px 5px rgb(0 0 0 / 8%),
    inset 0 1px 0 #f5f5f7 !important;
  border-radius: 4px !important;
  margin: 10px 15px 20px !important;
  padding: 3px !important;
  border-style: none !important;
  font-family: "SF Pro Text" !important;
}


.SubmitButtonpaypal {
  display: block;
  font-size: 16px;
  width: calc(100% - 60px);
  height: 40px;
  margin: 0px 15px 0;
  background-color: #3c71ff;
  box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%), inset 0 1px 0 #3c71ff;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all .1s ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: aliceblue;
}

.paypalError {
  color: rgb(241, 0, 0);
  font-size: small;
  font-family: sans-serif !important;
}

.paypal-container {
  width: 100%;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.text-center{
  text-align: center !important;
}